import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import LocalizedLink from "./localizedLink";
import useTranslations from "../hooks/useTranslations";
import { LocaleContext } from "./layout";

export default function Footer() {
  const { locale } = React.useContext(LocaleContext);
  const { footer } = useTranslations();
  const data = useStaticQuery(graphql`
    {
      allGoogleMenuSheet {
        nodes {
          menuNameDe
          menuNameEn
          menuBeschreibungDe
          menuBeschreibungEn
          type
        }
      }
      allGoogleOeffnungszeitenSheet {
        nodes {
          id
          sortId
          titleDe
          dateRangeDe
          weekdayRangeDe
          exceptionWeekdayRangeDe
          timeRangeDe
          exceptionTimeRangeDe
          titleEn
          dateRangeEn
          weekdayRangeEn
          exceptionWeekdayRangeEn
          timeRangeEn
          exceptionTimeRangeEn
          getCurrentYear
          group
        }
      }
    }
  `);

  const boatTypes = [
    {
      name:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[0].menuNameDe
          : data.allGoogleMenuSheet.nodes[0].menuNameEn,
      description:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[0].menuBeschreibungDe
          : data.allGoogleMenuSheet.nodes[0].menuBeschreibungEn,
      href: "/boote?license=no",
      // icon: ShieldCheckIcon,
    },
    {
      name:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[1].menuNameDe
          : data.allGoogleMenuSheet.nodes[1].menuNameEn,
      description:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[1].menuBeschreibungDe
          : data.allGoogleMenuSheet.nodes[1].menuBeschreibungEn,
      href: "/boote?license=yes",
    },
    {
      name:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[2].menuNameDe
          : data.allGoogleMenuSheet.nodes[2].menuNameEn,
      description:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[2].menuBeschreibungDe
          : data.allGoogleMenuSheet.nodes[2].menuBeschreibungEn,
      href: "/boote?boatType=floss",
    },
    {
      name:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[3].menuNameDe
          : data.allGoogleMenuSheet.nodes[3].menuNameEn,
      description:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[3].menuBeschreibungDe
          : data.allGoogleMenuSheet.nodes[3].menuBeschreibungEn,
      href: "/boote?boatType=yacht",
    },
    {
      name:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[4].menuNameDe
          : data.allGoogleMenuSheet.nodes[4].menuNameEn,
      description:
        locale === "de"
          ? data.allGoogleMenuSheet.nodes[4].menuBeschreibungDe
          : data.allGoogleMenuSheet.nodes[4].menuBeschreibungEn,
      href: "/boote?boatType=muskelkraft",
    },
  ];

  return (
    <footer className="pt-16 pb-12 bg-gray-50 sm:pt-20 md:pt-24 xl:pt-32 sm:pb-20">
      <div className="max-w-screen-lg px-4 mx-auto divide-y divide-gray-200 xl:max-w-screen-xl sm:px-6 md:px-8">
        <ul className="grid font-medium pb-14 sm:pb-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10 gap-x-4">
          <li className="row-span-2 space-y-5">
            <h2 className="font-semibold tracking-wide uppercase text-als-red">
              {footer.bookOnline}
            </h2>
            <ul className="space-y-4">
              {boatTypes.map((item) => (
                <li key={item.name}>
                  <LocalizedLink
                    to={item.href}
                    className="transition-colors duration-200 hover:text-gray-900"
                  >
                    {item.name}
                  </LocalizedLink>
                </li>
              ))}
            </ul>
          </li>
          <li className="row-span-2 space-y-5">
            <h2 className="font-semibold tracking-wide uppercase text-als-red">
              {footer.directions}
            </h2>
            <ul className="space-y-4">
              <li>
                <strong>{footer.address}:</strong>
                <br />
                <address className="not-italic">
                  {footer.street}
                  <br />
                  {footer.zipCode} {footer.city}
                </address>
              </li>
              <li>
                <strong>{footer.publicTransport}</strong>
                <br />
                {footer.tram}
                <br />
                {footer.tramStop}
                <br />
                {footer.walkingDistance}
              </li>
              <li>
                <a href="https://g.page/als-bootsverleih">
                  {footer.openWithMaps}
                </a>
              </li>
            </ul>
          </li>
          <li className="row-span-2 space-y-5">
            <div className="row-span-2 space-y-5">
              <h2 className="font-semibold tracking-wide uppercase text-als-red">
                {footer.openingHours}
              </h2>
              <ul className="space-y-4">
                {data.allGoogleOeffnungszeitenSheet.nodes.map((node) => {
                  if (node.group === "specials") return false;
                  const title = locale === "de" ? node.titleDe : node.titleEn;
                  const currentYear = new Date().getFullYear();
                  let dateRange =
                    locale === "de" ? node.dateRangeDe : node.dateRangeEn;
                  if (node.getCurrentYear) {
                    dateRange = dateRange + currentYear;
                  }
                  const timeRange =
                    locale === "de" ? node.timeRangeDe : node.timeRangeEn;
                  const weekdayRange =
                    locale === "de" ? node.weekdayRangeDe : node.weekdayRangeEn;
                  const divider = ": ";
                  let timeAndWeekday = false;

                  if (timeRange && weekdayRange) {
                    timeAndWeekday = weekdayRange + divider + timeRange;
                  } else if (timeRange) {
                    timeAndWeekday = timeRange;
                  } else if (weekdayRange) {
                    timeAndWeekday = weekdayRange;
                  }

                  const exceptionTimeRange =
                    locale === "de"
                      ? node.exceptionTimeRangeDe
                      : node.exceptionTimeRangeEn;
                  const exceptionWeekdayRange =
                    locale === "de"
                      ? node.exceptionWeekdayRangeDe
                      : node.exceptionWeekdayRangeEn;
                  let exceptionTimeAndWeekday = false;

                  if (exceptionTimeRange && exceptionWeekdayRange) {
                    exceptionTimeAndWeekday =
                      exceptionWeekdayRange + divider + exceptionTimeRange;
                  } else if (exceptionTimeRange) {
                    exceptionTimeAndWeekday = exceptionTimeRange;
                  } else if (exceptionWeekdayRange) {
                    exceptionTimeAndWeekday = exceptionWeekdayRange;
                  }
                  return (
                    <li className="flex flex-col" key={node.id}>
                      {title && <strong>{title}:</strong>}
                      {dateRange && <span>{dateRange}</span>}
                      {exceptionTimeAndWeekday && (
                        <span>{exceptionTimeAndWeekday}</span>
                      )}
                      {timeAndWeekday && <span>{timeAndWeekday}</span>}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="row-span-2 space-y-5">
              <h2 className="font-semibold tracking-wide uppercase text-als-red">
                Specials
              </h2>
              <ul className="space-y-4 text-sm">
                <li className="flex flex-col">
                  <strong>
                  {locale === "de" ? "Ab 21 Uhr mit Skipper:" : "With skipper after 9pm:"}
                  </strong>
                  <br />
                    {locale === "de" ? "Gilt nur für Flöße." : "Only for rafts."}

                </li>
                {data.allGoogleOeffnungszeitenSheet.nodes.map((node) => {
                  if (node.group === "openingHours") return false;
                  const title = locale === "de" ? node.titleDe : node.titleEn;
                  const currentYear = new Date().getFullYear();
                  let dateRange =
                    locale === "de" ? node.dateRangeDe : node.dateRangeEn;
                  if (node.getCurrentYear) {
                    dateRange = dateRange + currentYear;
                  }
                  const timeRange =
                    locale === "de"
                      ? node.timeRangeDe ?? false
                      : node.timeRangeEn ?? false;
                  const weekdayRange =
                    locale === "de"
                      ? node.weekdayRangeDe ?? false
                      : node.weekdayRangeEn ?? false;
                  const divider = weekdayRange && timeRange ? " | " : false;
                  //concat time and weekday
                  const timeAndWeekday =
                    timeRange && weekdayRange
                      ? timeRange + divider + weekdayRange
                      : timeRange
                      ? timeRange
                      : weekdayRange
                      ? weekdayRange
                      : false;
                  return (
                    <li className="flex flex-col" key={node.id}>
                      {title && <strong>{title}:</strong>}
                      {dateRange && <span>{dateRange}</span>}
                      {timeAndWeekday && <span>{timeAndWeekday}</span>}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
          <li className="space-y-5">
            <h2 className="font-semibold tracking-wide uppercase text-als-red">
              Legal
            </h2>
            <ul className="space-y-4">
              <li>
                <LocalizedLink to="/kontakt">{footer.contact}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/faq">{footer.faqs}</LocalizedLink>
              </li>
              <li>
                <a
                  href="/allgemeine-gescharfts-und-mietbedingungen-als-bootsverleih.pdf"
                  title="download"
                >
                  {footer.gtcs}
                </a>
              </li>
              <li>
                <LocalizedLink to="/impressum">{footer.imprint}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/datenschutz">
                  {footer.privacyPolicy}
                </LocalizedLink>
              </li>
            </ul>
          </li>
        </ul>
        <div className="pt-6 font-light leading-relaxed text-center uppercase md:pt-12">
          © {new Date().getFullYear()} ALS Bootsverleih®.{" "}
          {footer.reservedRights}
          <br />
          {footer.softwareBy}
          <a
            className="px-1 ml-1 underline bg-als-red/10 hover:bg-black hover:text-white hover:no-underline"
            href="https://rentware.com/?utm_source=als_bootsverleih&utm_medium=footer_link&utm_campaign=ref"
          >
            Rentware
          </a>
        </div>
      </div>
    </footer>
  );
}
